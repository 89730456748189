import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './parts/footer/footer.component';
import { HeaderComponent } from './parts/header/header.component';
import { PageComponent } from './page.component';
import { PrimeNGModule } from 'src/app/components/shared/prime-ng.module';



@NgModule({
  declarations: [
    PageComponent,
    FooterComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    PrimeNGModule
  ],
  exports: [
    PageComponent
  ]
})
export class PageModule { }
