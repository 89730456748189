import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-auth-login-button',
  template: '<button (click)="login()">Log in</button>',
  styleUrls: []
})
export class AuthLoginButtonComponent {
  // Inject the authentication service into your component through the constructor
  constructor(public auth: AuthService) {}

  login(): void {
    // Call this to redirect the user to the login page
    this.auth.loginWithRedirect();
  }
}