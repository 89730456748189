import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {
  @Input() label?: string;
  @Input() size?: 'small' | 'medium' | 'large';
  sizeClass!: string;

  ngOnInit(): void {
    this.label = this.label || 'Loading ...';
    this.size = this.size || 'large';
    switch (this.size) {
      case 'small': 
        this.sizeClass = 'w-2rem h-2rem';
        break;
      case 'medium':
        this.sizeClass = 'w-4rem h-4rem';
        break;
      default:
        this.sizeClass = 'w-5rem h-5rem';
        break;
    }
  }
}
