import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageModule } from './page/page.module';
import { PageComponent } from './page/page.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ModalsModule } from '../modals/modals.module';
import { PrimeNGModule } from './prime-ng.module';
import { AuthLoginButtonComponent } from './auth-login-button/auth-login-button.component';
import { AuthLogoutButtonComponent } from './auth-logout-button/auth-logout-button.component';
import { AuthModule } from '@auth0/auth0-angular';



@NgModule({
  declarations: [
    LoadingSpinnerComponent,
    AuthLoginButtonComponent,
    AuthLogoutButtonComponent
  ],
  imports: [
    CommonModule,
    PageModule,
    ModalsModule,
    PrimeNGModule,
    AuthModule
  ],
  exports: [
    PageComponent,
    LoadingSpinnerComponent,
    AuthLoginButtonComponent,
    AuthLogoutButtonComponent
  ]
})
export class SharedModule { }
