export const RESCHEDULE_REASONS = [
    {
        codeReasonId : 24,
        code : 'CDT',
        description : 'Customer Requests different date time',
        codeReasonType : 'Reschedule',
        codeReasonStatus : true
    },
    {
        codeReasonId : 25,
        code : 'CNH',
        description : 'Customer Not Home',
        codeReasonType : 'Reschedule',
        codeReasonStatus : true
    },
    {
        codeReasonId : 26,
        code : 'CNS',
        description : 'Customer No Show',
        codeReasonType : 'Reschedule',
        codeReasonStatus : true
    },
    {
        codeReasonId : 27,
        code : 'MTR',
        description : 'Multiple Technicians Required',
        codeReasonType : 'Reschedule',
        codeReasonStatus : true
    },
    {
        codeReasonId : 28,
        code : 'RDC',
        description : 'Request New/Different Servicer',
        codeReasonType : 'Reschedule',
        codeReasonStatus : true
    },
    {
        codeReasonId : 29,
        code : 'RFP',
        description : 'Reschedule For Parts',
        codeReasonType : 'Reschedule',
        codeReasonStatus : true
    },
    {
        codeReasonId : 30,
        code : 'RVR',
        description : 'Return Visit Rescheduled',
        codeReasonType : 'Reschedule',
        codeReasonStatus : true
    },
    {
        codeReasonId : 31,
        code : 'SDT',
        description : 'Servicer Requested Different Appointment Time',
        codeReasonType : 'Reschedule',
        codeReasonStatus : true
    },
    {
        codeReasonId : 32,
        code : 'OTH',
        description : 'Other',
        codeReasonType : 'Reschedule',
        codeReasonStatus : true
    },
];