import { Component, OnInit } from '@angular/core';

import { get, isEmpty, isEqual } from 'lodash';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { of } from 'rxjs';
import { map, retry, take } from 'rxjs/operators';

import { RESCHEDULE_REASONS } from './confirm.constant';
import { IReason } from './confirm.model';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  disabled = false;
  // reassign operation will be added here once we pull in reassign ticket
  operation = {} as { rescheduleReason?: IReason };
  rescheduleReasons: IReason[] = [];
  tooltipText = '';

  constructor(public config: DynamicDialogConfig,  public ref: DynamicDialogRef) {}

  ngOnInit(): void {
    // load reschedule reasons if the operation is reschedule
    if (isEqual(get(this, 'config.data.operation'), 'reschedule')) {
      this.rescheduleReasonsLoad();
      this.disabledSet();
      this.tooltipTextSet();
    }
  }

  // close the modal
  closeConfirm(confirm: string): void {
    const confirmResponse = isEqual(confirm, 'yes')
      ? {
        confirm,
        reasons: { reschedule: this.operation.rescheduleReason },
       }
      : { confirm }

    if ((!this.disabled && isEqual(confirm, 'yes')) || isEqual(confirm, 'no')) {
      this.ref.close(confirmResponse);
    }
  }

  // set disabled once the reasons are loaded
  disabledSet(): void {
    // add condition for disabled once reassign is pulled
    this.disabled = (isEqual(get(this, 'config.data.operation'), 'reschedule')) && isEmpty(this.operation.rescheduleReason);
  }

  rescheduleReasonsLoad(): void {
    of(RESCHEDULE_REASONS).pipe(
      take(1),
      retry(1),
      map((reasons) => {
        this.rescheduleReasons = reasons;
      })
    ).subscribe();
  }

  // set tooltip according to the operation
  tooltipTextSet(): void {
    this.tooltipText = `Select reason(s) to ${this.config.data.operation}`;
  }
}
