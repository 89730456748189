import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  log(value:string|Array<unknown>):void {
    if (Array.isArray(value)) {
      return console.table(value);
    }
    return console.log(value);
  }
}
