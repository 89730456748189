import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoggerService } from './logger/logger.service';
// import { AppAuthService } from './auth/app.auth.service';
import { SharedModule } from 'primeng/api';

@NgModule({
})
export class ServicesModule { 
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [
        LoggerService,
        // AppAuthService,
      ]
    }
  }
}
