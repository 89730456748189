<div class="confirm-modal">
    <div class="modal-body">
        <form reasonForm="ngForm">
            <div class="fields" *ngIf="rescheduleReasons.length">
                <label>{{config.data.label}} *</label>
                <p-dropdown
                    #rescheduleReason="ngModel"
                    [(ngModel)]="operation.rescheduleReason"
                    [ngClass]="{'error-field':
                        (!rescheduleReason?.valid && (rescheduleReason?.dirty || rescheduleReason?.touched))
                        && rescheduleReason.errors?.['required']
                    }"
                    [options]="rescheduleReasons"
                    [required]="true"
                    (ngModelChange)="disabledSet()"
                    name="rescheduleReason"
                    optionLabel="description"
                    placeholder="Select reason"
                ></p-dropdown>
                <ng-container *ngIf="!rescheduleReason?.valid && (rescheduleReason?.dirty || rescheduleReason?.touched)">
                    <small *ngIf="rescheduleReason.errors?.['required']">Reschedule reason is required</small>
                </ng-container>
            </div>
        </form>
    </div>

    <div class="button-group">
        <p-button
            [disabled]="disabled"
            [label]="config.data?.confirm"
            [pTooltip]="tooltipText"
            [tooltipDisabled]="!disabled"
            (click)="closeConfirm('yes')"
            styleClass="p-button-sm p-button-success"
            tooltipPosition="left"
            tooltipStyleClass="tooltip"
        ></p-button>
        <p-button
            [label]="config.data?.cancel"
            (click)="closeConfirm('no')"
            styleClass="p-button-sm p-button-outlined p-button-success"
        ></p-button>
    </div>
</div>

