import { Component } from '@angular/core';

import { get, isEqual } from 'lodash';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map, take } from 'rxjs/operators';

import { ConfirmComponent } from 'src/app/components/modals/confirm/confirm.component';
import { IReason } from 'src/app/components/modals/confirm/confirm.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  constructor(public dialogService: DialogService, public ref: DynamicDialogRef) {}

  operationConfirm(): void {
    // use same method for reassignConfirm
    // create variables with ternary operators
    // bind operation, label and confirm of data for reassign
    // bind header for reassign
    this.dialogService.open(
      ConfirmComponent,
        {
          closeOnEscape: true,
          data: {
            operation: 'reschedule',
            label: 'Reschedule Reason',
            confirm: 'Reschedule',
            cancel: 'Cancel',
          },
          header: 'Reschedule ?',
          height: '25%',
          width: '36%',
        },
    ).onClose.pipe(
      take(1),
      map((response) => {
        // do nothing if response.confirm is no
        if (isEqual(get(response, 'confirm'), 'yes')) {
          const rescheduleReason = get(response, 'reasons.reschedule');
          // deal with reassign while pulling reassign ticket
          // call reschedule if rescheduleReason is available
          if (rescheduleReason) {
            this.reschedule(rescheduleReason);
          }
        }
      })
    ).subscribe();
  }


  reschedule(reason: IReason): void {
    // todo with api integration
    // console log will be removed once the flow to reschedule is added
    console.log(reason);
    // navigate to timeslot page after checking availability from api
  }
}
