<footer class="grid">
    <div class="col">
        <p-button label="Save All" styleClass="p-button-sm p-button-raised p-button-success"></p-button>
    </div>

    <div class="col actions">
        <p-button label="Multi-item" styleClass="p-button-sm p-button-raised p-button-success"></p-button>
        <p-button label="Reassign" styleClass="p-button-sm p-button-raised p-button-outlined p-button-success"></p-button>
        <p-button
            (click)="operationConfirm()"
            label="Reschedule"
            styleClass="p-button-sm p-button-raised p-button-outlined p-button-success"
        ></p-button>
        <p-button label="Cancel Service Job" styleClass="p-button-sm p-button-raised p-button-danger"></p-button>
    </div>
</footer>
