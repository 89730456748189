import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { AuthGuard } from '@auth0/auth0-angular';
// import { LoginComponent } from './components/pages/login/login.component';
// import { JobDetailsComponent } from './modules/job-details/job-details.component';

// landing page is set as job-details for now with lazy loading
// { path: '', redirectTo: '/', pathMatch: 'full' },
const routes: Routes = [
  {
    path: 'job-details',
    redirectTo: '',
    pathMatch: 'full',
    // canActivate: [AuthGuard],
  },
  {
    path: '',
    // component: JobDetailsComponent
    loadChildren: () =>
      import('./components/pages/job-details/job-details.module').then(
        (m) => m.JobDetailsModule
      ),
    // canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./components/pages/login/login.module').then(
        (m) => m.LoginModule
      ),
    // component: LoginComponent
  },
  { 
    path: 'callback', 
    // component: LoginComponent
    loadChildren: () => import('./components/pages/callback/callback.module').then(m => m.CallbackModule) 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
